import * as React from 'react';
import {
    Box,
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerOverlay,
    Flex,
    useBoolean,
    useBreakpointValue,
} from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { useInView } from 'framer-motion';
import Joyride, { ACTIONS, StoreState } from 'react-joyride';
import Tooltip from '../components/common/Onboarding/Tooltip';
import BreachMonitoringWidget from '../components/dashboard/BreachMonitoringWidget';
import DiscoverArticlesWidget from '../components/dashboard/DiscoverArticlesWidget';
import NewArticlesWidget from '../components/dashboard/NewArticlesWidget';
import RecommendationWidget from '../components/dashboard/RecommendationWidget';
import SecurityAssessmentWidget from '../components/dashboard/SecurityAssessmentWidget';
import WelcomeBoard from '../components/dashboard/WelcomeBoard';
import { AppFeatures, DASHBOARD_SECTIONS } from '../config/config';
import { account } from '../entities/session';
import { fetchDashboardArticles, fetchOnboardingSteps } from '../services/apiService';
import { isFeatureEnabled } from '../utils/helpers';
import renderDocument from '../utils/renderDocument';
import onboardingImage from '../assets/images/onboarding.png';
import { OnboardingCarousel } from '../components/onboarding/OnboardingCarousel';

const { useRef } = React;

const DashboardPage = ({ onView = () => {} }: { onView?: Function }) => {
    const welcomeRef = useRef(null);
    const isWelcomeOnView = useInView(welcomeRef);

    const overviewRef = useRef(null);
    const isOverviewOnView = useInView(overviewRef);

    const discoverRef = useRef(null);
    const isDiscoverOnView = useInView(discoverRef);

    const [runOnboarding, setRunOnboarding] = useBoolean();
    const isMobile = useBreakpointValue(
        {
            base: true,
            md: false,
        },
        {
            fallback: 'base',
        }
    );

    if (isDiscoverOnView) {
        onView(DASHBOARD_SECTIONS.DISCOVER);
    } else if (isOverviewOnView) {
        onView(DASHBOARD_SECTIONS.OVERVIEW);
    } else if (isWelcomeOnView) {
        onView(DASHBOARD_SECTIONS.WELCOME);
    } else {
        onView(DASHBOARD_SECTIONS.WELCOME);
    }

    const accountInfo = account.use();

    const { data: articleData, isFetching: articleFetching } = useQuery<any>(
        ['dashboard_articles'],
        () => {
            return fetchDashboardArticles();
        }
    );

    const { data: onboardingSteps } = useQuery<any>(['onboarding'], {
        queryFn: () => fetchOnboardingSteps(),
        select: steps =>
            Object.values(steps).map(
                ({
                    title,
                    target,
                    placement,
                    content,
                    buttonLabel,
                    image,
                    icon,
                }: any) => ({
                    title,
                    target,
                    placement,
                    content: renderDocument(content),
                    buttonLabel,
                    image: image.fields.file.url,
                    icon: icon.fields.file.url,
                })
            ),
        onSuccess: () => setRunOnboarding.on(),
        enabled: accountInfo && 'isFirstLogin' in accountInfo && accountInfo.isFirstLogin,
    });

    const handleOnboardingChange = (state: Partial<StoreState>) => {
        if (state.action === ACTIONS.CLOSE) {
            setRunOnboarding.off();
        }
    };

    return (
        <Box
            id={'dashboard-page'}
            w={'100%'}
            p={{ base: '0', md: '24px 0 0 24px' }}
            mb={'10px'}
            gap={0}
        >
            {isFeatureEnabled(AppFeatures.ONBOARDING) && !isMobile && (
                <Joyride
                    callback={handleOnboardingChange}
                    scrollToFirstStep
                    continuous={true}
                    steps={onboardingSteps}
                    tooltipComponent={Tooltip}
                    hideBackButton={true}
                    run={runOnboarding}
                    disableScrolling={true}
                />
            )}

            {isFeatureEnabled(AppFeatures.ONBOARDING) && isMobile && (
                <Drawer
                    onClose={setRunOnboarding.off}
                    isOpen={runOnboarding}
                    size={'full'}
                >
                    <DrawerOverlay />
                    <DrawerContent>
                        <DrawerCloseButton
                            zIndex={9999}
                            _focusVisible={{ outline: 'none' }}
                        />
                        <DrawerBody p={0}>
                            <Box
                                bgGradient={
                                    'linear-gradient(124.86deg, #F0F0F5 0%, #D1D1E0 100%)'
                                }
                                _before={{
                                    content: '""',
                                    position: 'absolute',
                                    background: `url(${onboardingImage})`,
                                    display: 'block',
                                    height: 'full',
                                    width: '100%',
                                    opacity: 0.24,
                                    backgroundSize: 'auto',
                                    backgroundPosition: 'center',
                                    pointerEvents: 'none',
                                }}
                            >
                                <OnboardingCarousel
                                    callback={handleOnboardingChange}
                                    steps={onboardingSteps}
                                />
                            </Box>
                        </DrawerBody>
                    </DrawerContent>
                </Drawer>
            )}

            <Box
                id={`box-${DASHBOARD_SECTIONS.WELCOME}`}
                m={0}
                p={{ base: '16px 16px 0 16px', md: 0 }}
                ref={welcomeRef}
            >
                <WelcomeBoard />
            </Box>
            <Box id={`box-${DASHBOARD_SECTIONS.OVERVIEW}`} ref={overviewRef}>
                <Flex
                    id={`flex-${DASHBOARD_SECTIONS.OVERVIEW}`}
                    direction={{ base: 'column', md: 'row' }}
                    gap={{ base: 2, md: 6 }}
                >
                    <BreachMonitoringWidget />
                    <SecurityAssessmentWidget />
                    {isFeatureEnabled(AppFeatures.RECOMMENDATION_ADS) ? (
                        <RecommendationWidget />
                    ) : (
                        <NewArticlesWidget
                            items={articleData?.latestHappenings}
                            isLoading={articleFetching}
                        />
                    )}
                </Flex>
            </Box>
            <Box id={`box-${DASHBOARD_SECTIONS.DISCOVER}`} mt={'80px'} ref={discoverRef}>
                <Flex direction={{ base: 'column', md: 'row' }} gap={4}>
                    {isFeatureEnabled(AppFeatures.RECOMMENDATION_ADS) && (
                        <NewArticlesWidget
                            items={articleData?.latestHappenings}
                            isLoading={articleFetching}
                        />
                    )}
                    <DiscoverArticlesWidget
                        isLoading={articleFetching}
                        items={articleData?.discoverSecurity}
                    />
                </Flex>
            </Box>
        </Box>
    );
};

export default DashboardPage;

import { Box, Flex, Spinner } from '@chakra-ui/react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import QuestionWizardCard from '../components/assessment/wizardCards/QuestionWizardCard';
import TestOverviewWizardCard from '../components/assessment/wizardCards/TestOverviewWizardCard';
import TestReviewWizardCard from '../components/assessment/wizardCards/TestReviewWizardCard';
import Wizard from '../components/common/Wizard/Wizard';
import { AppRoutes, QueryKeys } from '../config/config';
import { account } from '../entities/session';
import useErrorToast from '../hooks/useErrorToast';
import useWizard from '../hooks/useWizard';
import { AppConfig } from '../config/config';
import {
    fetchAssessmentQuestions,
    fetchAssessmentResult,
    postAssessmentResult,
} from '../services/apiService';

export const AssessmentPage = () => {
    const accountInfo = account.use();
    const email = accountInfo.primaryEmail.email || accountInfo.primaryEmail;
    const navigate = useNavigate();
    const errToast = useErrorToast();
    const { retake } = useParams();

    const { mutate: submitAnswers, isLoading: isSubmitting } = useMutation({
        mutationFn: postAssessmentResult,
        onSuccess: data => {
            window.dataLayer.push({
                event: 'SecurityAdvisor_DL_Event Assessment Started',
                System: 'Security Advisor',
                Partner: AppConfig.carrier,
                Program: AppConfig.program,
                ID: '',
                // Timestamp: now(),
                Module: 'Assessment',
                MainSubscriberEmail: email,
            });
            navigate(AppRoutes.ASSESSMENT_RESULT);
        },
        onError: (err: AxiosError<Error>) => {
            errToast(err);
        },
    });

    const { data: assessmentResults, isLoading: isFetchingAssessmentResult } = useQuery({
        queryKey: QueryKeys.assessments.result(email),
        queryFn: () => fetchAssessmentResult(email),
        enabled: !!email,
        onSuccess: data => {
            if (data?.score >= 0 && !retake) {
                navigate(AppRoutes.ASSESSMENT_RESULT);
            }
        },
    });

    const { data: questions, isLoading: isFetchingQuestions } = useQuery({
        queryKey: QueryKeys.assessments.questions,
        queryFn: fetchAssessmentQuestions,
        enabled: retake ? true : !assessmentResults?.score && !isFetchingAssessmentResult,
    });

    const [editMode, setEditMode] = useState(false);
    const {
        handleNextStep,
        handlePrevStep,
        currStep,
        hasPrevStep,
        currStepData,
        answers,
        currAnswer,
        handleAnswer,
        isPre,
        isPost,
        goToStep,
    } = useWizard({
        steps: questions,
        hasPre: true,
        hasPost: true,
    });

    if (isFetchingAssessmentResult || isFetchingQuestions) {
        return (
            <Box w={'100%'} textAlign={'center'} mt={'40px'} mb={'40px'}>
                <Spinner variant={'componentLoader'} />
            </Box>
        );
    }

    return (
        <Flex flexDir="column" w="full" id={'assessment-page'}>
            <Wizard>
                {isPre && (
                    <TestOverviewWizardCard
                        onNext={() => {
                            window.dataLayer.push({
                                event: 'SecurityAdvisor_DL_Event Assessment Started',
                                System: 'Security Advisor',
                                Partner: AppConfig.carrier,
                                Program: AppConfig.program,
                                ID: '',
                                // Timestamp: now(),
                                Module: 'Assessment',
                                MainSubscriberEmail: email,
                            });
                            handleNextStep();
                        }}
                    />
                )}
                {!isPre && !isPost && (
                    <QuestionWizardCard
                        data={currStepData}
                        prevButtonProps={{
                            disabled: !hasPrevStep || editMode,
                            onClick: () => handlePrevStep(),
                        }}
                        nextButtonProps={{
                            onClick: () => {
                                if (editMode) {
                                    setEditMode(false);
                                    goToStep('post');
                                } else {
                                    handleNextStep();
                                }
                            },
                        }}
                        step={currStep}
                        numSteps={questions?.length}
                        value={currAnswer}
                        onOptionClick={answer => handleAnswer(answer)}
                    />
                )}
                {isPost && (
                    <TestReviewWizardCard
                        questions={questions}
                        answers={answers}
                        onEdit={newStep => {
                            setEditMode(true);
                            goToStep(newStep);
                        }}
                        onSubmit={() => {
                            window.dataLayer.push({
                                event: 'SecurityAdvisor_DL_Event Assessment Completed',
                                System: 'Security Advisor',
                                Partner: AppConfig.carrier,
                                Program: AppConfig.program,
                                ID: '',
                                // Timestamp: now(),
                                Module: 'Assessment',
                                MainSubscriberEmail: email,
                            });
                            submitAnswers({
                                email,
                                answers,
                            });
                        }}
                        isLoading={isSubmitting}
                        email={email}
                    />
                )}
            </Wizard>
        </Flex>
    );
};

export default AssessmentPage;

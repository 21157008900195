import {
    Box,
    Container,
    Flex,
    HStack,
    Image,
    SimpleGrid,
    Skeleton,
    Spacer,
    Text,
} from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import Breadcrumbs from '../components/common/Breadcrumbs';
import RouteLink from '../components/common/RouteLink';
import ArticleTopicFilter from '../components/discover/ArticleTopicFilter';
import { AppRoutes, QueryKeys } from '../config/config';
import { IBreadcrumbItem } from '../config/interface';
import { getPageStyles } from '../config/theme';
import { securityArticles } from '../entities/articles';
import useContent from '../hooks/useContent';
import useLogout from '../hooks/useLogout';
import { fetchArticleTopics, fetchSecurityArticles } from '../services/apiService';
import { getResponseError } from '../utils/helpers';

const DiscoverSecurityPage = () => {
    const content = useContent();
    const doLogout = useLogout();
    const { main, top, title, filter, filterTitle } =
        getPageStyles('DiscoverSecurityPage');

    const [selectedTopic, setSelectedTopic] = useState<string>('all');

    const breadcrumbItems: IBreadcrumbItem[] = [
        { label: 'Dashboard', href: AppRoutes.DASHBOARD, current: false },
        { label: 'Discover Security', href: AppRoutes.DISCOVER, current: true },
    ];

    let {
        data: topics,
        error: topicError,
        isLoading: topicFetching,
    } = useQuery<string[]>(QueryKeys.articles.topics, () => fetchArticleTopics(), {
        onError: topicError => {
            const { status } = getResponseError(topicError);
            if (status === 401) {
                doLogout();
            }
        },
    });

    if (topics && !topicFetching && !topicError) {
        let newTopics = ['all'];
        for (let x = 0; x < topics.length; x++) {
            newTopics.push(topics[x]);
        }
        topics = newTopics;
    }

    const { data: articles } = useQuery(
        QueryKeys.articles.list(selectedTopic),
        async () => fetchSecurityArticles({ topic: selectedTopic }),
        {
            enabled: topics && topics.length > 0 && selectedTopic ? true : false,
            onError: articleError => {
                const { status } = getResponseError(articleError);
                if (status === 401) {
                    doLogout();
                }
            },
        }
    );

    useEffect(() => {
        if (articles && articles.length > 0 && selectedTopic === 'all') {
            let _articles = {};
            for (let x = 0; x < articles.length; x++) {
                const article = articles[x];
                const { slug } = article;
                _articles[slug] = article;
            }
            securityArticles.set(_articles);
        }
    }, [articles, selectedTopic]);

    return (
        <Box id={'discoverysecuritypage'} {...main}>
            <Flex direction={'column'} gap={0}>
                <Box backgroundColor={'white'} p={'14px 0 14px 14px'}>
                    <Breadcrumbs items={breadcrumbItems} currentOnly={false} />
                </Box>

                <Box {...top}>
                    <Text {...title}>{content('discover.title')}</Text>
                    {/* <Text {...subtitle1}>{content('discover.subtitle')}</Text> */}
                </Box>
                <Box {...filter}>
                    {topics && topics.length > 0 && (
                        <HStack overflow={'auto'}>
                            <Box>
                                <Text {...filterTitle}>Topics</Text>
                            </Box>
                            <ArticleTopicFilter
                                topics={topics}
                                selectedTopic={selectedTopic}
                                onSelectTopic={
                                    !topicFetching
                                        ? topic => setSelectedTopic(topic)
                                        : () => {}
                                }
                            />
                        </HStack>
                    )}
                </Box>
                <Box backgroundColor={'white'}>
                    <Container maxW="container.xl" mt={6} mb={12}>
                        <SimpleGrid
                            gridTemplateColumns="repeat(auto-fit, minmax(342px, 342px))"
                            spacing={6}
                            justifyContent="center"
                        >
                            {topicFetching && (
                                <>
                                    <Skeleton h="300px" w="full" />
                                    <Skeleton h="300px" w="full" />
                                    <Skeleton h="300px" w="full" />
                                    <Skeleton h="300px" w="full" />
                                    <Skeleton h="300px" w="full" />
                                    <Skeleton h="300px" w="full" />
                                </>
                            )}
                            {articles?.map(
                                ({
                                    thumbnail,
                                    slug,
                                    title,
                                    subTitle,
                                    updatedAt,
                                    tags,
                                    timeToRead,
                                }) => (
                                    <Flex
                                        as={RouteLink}
                                        flexDir="column"
                                        border="1px solid #DDDDDD"
                                        borderRadius="8px"
                                        overflow="hidden"
                                        to={AppRoutes.DISCOVER_ARTICLE.replaceAll(
                                            ':slug',
                                            slug
                                        )}
                                    >
                                        <Image
                                            src={thumbnail?.file?.url}
                                            maxH="228px"
                                            overflow="hidden"
                                            objectFit="cover"
                                        />
                                        <Flex flexDir="column" p={4} flexGrow={1}>
                                            <Text
                                                color="brand.primary.500"
                                                fontWeight="bold"
                                            >
                                                {title}
                                            </Text>
                                            <Text mt={4} fontSize="lg" fontWeight="bold">
                                                {subTitle}
                                            </Text>
                                            <Spacer minH="24px" />
                                            <Text color="#6E767D">
                                                {format(
                                                    new Date(updatedAt),
                                                    'dd MMM yyyy'
                                                )}
                                                {!!timeToRead && ` • ${timeToRead}`}
                                            </Text>
                                        </Flex>
                                    </Flex>
                                )
                            )}
                        </SimpleGrid>
                    </Container>
                </Box>
            </Flex>
        </Box>
    );
};

export default DiscoverSecurityPage;

import {
    Flex,
    Link,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Text,
} from '@chakra-ui/react';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import React, { useState } from 'react';
import { AppConfig, AppRoutes } from '../../config/config';
import { getWidgetStyles } from '../../config/theme';
import { account } from '../../entities/session';
import useContent from '../../hooks/useContent';
import useErrorToast from '../../hooks/useErrorToast';
import { fetchAccountDetails, verifySecondaryEmail } from '../../services/apiService';
import { isMobileScreen } from '../../utils/helpers';
import OtpForm from './OtpForm';
import RouteLink from './RouteLink';

const AddEmailVerifyModal = ({
    emailToVerify,
    isOpen,
    onClose,
    onVerifiedSuccess,
    onVerifiedFailed,
}) => {
    const content = useContent();
    const accountInfo = account.use();
    const isMobile = isMobileScreen();
    const errToast = useErrorToast();
    const {
        content: contentModal,
        header,
        title,
        body,
    } = getWidgetStyles('AddEmailVerifyModal');

    const [otp, setOtp] = useState('');
    const [invalidOtp, setInvalidOtp] = useState(false);

    const { mutate: verifyAddEmail, isLoading: isVerifying } = useMutation({
        mutationFn: verifySecondaryEmail,
        onSuccess: (data: any) => {
            if (data && data.success) {
                // re-fetch user details to add the new email
                fetchUserDetails({ email: accountInfo?.primaryEmail?.email });
            }
        },
        onError: (error: AxiosError<Error>) => {
            errToast(error);
            setInvalidOtp(true);
        },
    });

    const { mutate: fetchUserDetails, isLoading: isFetching } = useMutation({
        mutationFn: fetchAccountDetails,
        onSuccess: accountData => {
            account.set(accountData.user);
            onVerifiedSuccess();
        },
        onError: (error: AxiosError<Error>) => {
            errToast(error);
        },
    });

    return (
        <>
            <Modal
                id={'add-email-verify-modal'}
                isOpen={isOpen}
                isCentered={isMobile ? false : true}
                onClose={onClose}
                closeOnOverlayClick={true}
            >
                <ModalOverlay />
                <ModalContent {...contentModal}>
                    <ModalHeader {...header}>
                        <Flex direction={'column'} gap={2}>
                            <Text {...title}>{content('common.verifyemail.title')}</Text>
                            <Text>
                                {content(
                                    'common.verifyemail.subtitle1',
                                    '{email}',
                                    (match, i) => {
                                        return (
                                            <span key={i}>
                                                <br />
                                                <strong>{emailToVerify}</strong>
                                            </span>
                                        );
                                    }
                                )}
                            </Text>
                            <Text>{content('common.verifyemail.subtitle2')}</Text>
                            <Link onClick={() => {}} textDecoration={'underline'}>
                                {content('common.verifyemail.resend')}
                            </Link>
                        </Flex>
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody {...body}>
                        <OtpForm
                            onFormSubmit={e => {
                                e.preventDefault();
                                verifyAddEmail({ secondaryEmail: emailToVerify, otp });
                            }}
                            rootProps={{ alignItems: 'baseline' }}
                            label={content('common.verifyemail.code')}
                            otpCount={6}
                            disclaimer={
                                <>
                                    By clicking 'Continue', you agree to our{' '}
                                    <RouteLink
                                        to={AppConfig.termsOfUseUrl || AppRoutes.LOGIN}
                                        textDecor="underline"
                                    >
                                        Terms of Use
                                    </RouteLink>{' '}
                                    and{' '}
                                    <RouteLink
                                        to={AppConfig.privacyPolicyUrl || AppRoutes.LOGIN}
                                        textDecor="underline"
                                    >
                                        Privacy Policy
                                    </RouteLink>
                                </>
                            }
                            buttonDisabled={
                                otp.length < 6 || otp.length > 6 || invalidOtp
                            }
                            buttonLabel={'Continue'}
                            isInvalid={invalidOtp}
                            isLoading={isVerifying || isFetching}
                            onChange={val => {
                                setOtp(val);
                                setInvalidOtp(false);
                            }}
                            onComplete={() => {}}
                        />
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
};

export default AddEmailVerifyModal;

import {
    Button,
    Flex,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Text,
} from '@chakra-ui/react';
import { useMutation } from '@tanstack/react-query';
import React, { useCallback, useState } from 'react';
import { AppRoutes } from '../../config/config';
import { NullableVar } from '../../config/interface';
import { getWidgetStyles } from '../../config/theme';
import { account } from '../../entities/session';
import useContent from '../../hooks/useContent';
import useErrorToast from '../../hooks/useErrorToast';
import useLogout from '../../hooks/useLogout';
import { registerSecondaryEmail } from '../../services/apiService';
import { createUrl, getResponseError, isMobileScreen } from '../../utils/helpers';

const AddEmailModal = ({ isOpen, onClose }) => {
    const content = useContent();
    const doLogout = useLogout();
    const errorToast = useErrorToast();
    const accountInfo = account.use();
    const { primaryEmail } = accountInfo;
    const {
        content: modalContent,
        header,
        title,
        body,
    } = getWidgetStyles('AddEmailModal');

    const [category, setCategory] = useState<NullableVar<string>>();
    const [email, setEmail] = useState<NullableVar<string>>();

    const { mutate: addSecondaryEmail, isLoading: isAdding } = useMutation({
        mutationFn: registerSecondaryEmail,
        onSuccess: data => {
            setEmail('');
            setCategory('');
            onClose();
            window.location.href = createUrl(
                AppRoutes.BREACH_VERIFY_EMAIL.replace(':email', email || '')
            );
        },
        onError: (error: any) => {
            const { status } = getResponseError(error);
            if (status === 401) {
                doLogout();
            } else {
                errorToast(error);
            }
        },
    });

    const handleAddSecondaryEmail = useCallback(() => {
        if (email && !isAdding) {
            addSecondaryEmail({
                secondaryEmail: email,
                tag: category || 'Secondary Email',
            });
        }
    }, [email, category, addSecondaryEmail, isAdding]);

    const isMobile = isMobileScreen();
    return (
        <>
            <Modal
                id={'add-email-modal'}
                isOpen={isOpen}
                isCentered={isMobile ? false : true}
                onClose={onClose}
                closeOnOverlayClick={true}
            >
                <ModalOverlay />
                <ModalContent {...modalContent}>
                    <ModalHeader {...header}>
                        <Flex direction={'column'} gap={2}>
                            <Text {...title}>{content('common.addemail.title')}</Text>
                            <Text>{content('common.addemail.subtitle1')}</Text>
                            <Text>{content('common.addemail.subtitle2')}</Text>
                        </Flex>
                    </ModalHeader>
                    <ModalCloseButton color={'white'} />
                    <ModalBody {...body}>
                        <form
                            onSubmit={e => {
                                e.preventDefault();
                                handleAddSecondaryEmail();
                            }}
                        >
                            <Flex direction={'column'} gap={2}>
                                <Text>{content('common.addemail.category')}</Text>
                                <Input
                                    type={'text'}
                                    placeholder={
                                        content('common.addemail.categoryPlaceholder') ||
                                        ''
                                    }
                                    value={category || ''}
                                    onChange={v => {
                                        setCategory(v.target.value);
                                    }}
                                />
                                <Text>{content('common.addemail.emailaddress')}</Text>
                                <Input
                                    type={'text'}
                                    placeholder={
                                        content(
                                            'common.addemail.emailaddressPlaceholder'
                                        ) || ''
                                    }
                                    value={email || ''}
                                    onChange={v => {
                                        setEmail(v.target.value);
                                    }}
                                />
                                <Button
                                    type="submit"
                                    variant={'solid'}
                                    onClick={e => {
                                        e.preventDefault();
                                        handleAddSecondaryEmail();
                                    }}
                                    disabled={isAdding}
                                >
                                    Send verification code
                                </Button>
                            </Flex>
                        </form>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
};

export default AddEmailModal;

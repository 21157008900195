import {
    Box,
    Container,
    Flex,
    Heading,
    SimpleGrid,
    Skeleton,
    Stack,
    Text,
} from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { Link, useParams } from 'react-router-dom';
import Breadcrumbs from '../components/common/Breadcrumbs';
import { AppRoutes, QueryKeys } from '../config/config';
import { fetchSecurityArticle } from '../services/apiService';
import useErrorToast from '../hooks/useErrorToast';
import { AxiosError } from 'axios';
import renderDocument from '../utils/renderDocument';
import { Block, BLOCKS, Inline, Text as CText } from '@contentful/rich-text-types';
import { imageAssetNodeOptions } from '../utils/contentfulRenderOptions';
import { useMemo } from 'react';
import { removeNumberedSubstring, toAnchorTag } from '../utils/helpers';
import { format } from 'date-fns';

const getContentValue = (content: (Block | Inline | CText)[] = []) =>
    content.map(data => (data as any)?.value || '').join('');

const DiscoverArticlePage = () => {
    const { slug } = useParams<{ slug: string }>();
    const errorToast = useErrorToast();

    const { data: article, isLoading } = useQuery(
        QueryKeys.articles.item(slug),
        () => fetchSecurityArticle(slug!),
        {
            onError: topicError => {
                errorToast(topicError as AxiosError<Error>);
            },
            enabled: !!slug,
        }
    );

    const sections = useMemo(() => {
        if (article?.articleSections?.content) {
            return article.articleSections.content
                .filter(({ nodeType }) => nodeType.includes('heading-'))
                .map(({ content }) => {
                    const text = removeNumberedSubstring(getContentValue(content));

                    return {
                        text,
                        id: toAnchorTag(text),
                    };
                });
        }

        return [];
    }, [article?.articleSections]);

    const isLoaded = !isLoading;

    return (
        <Box w={'100%'} position={'relative'} id={`discover-article-page-${slug || ''}`}>
            <Box
                display={{ base: 'block', md: 'none' }}
                backgroundColor={'white'}
                p={'14px 0 14px 14px'}
            >
                <Breadcrumbs
                    items={[
                        { label: 'Article', href: AppRoutes.DISCOVER, current: true },
                    ]}
                    currentOnly={true}
                />
            </Box>
            <Box
                display={{ base: 'none', md: 'block' }}
                backgroundColor={'white'}
                p={'14px 0 14px 14px'}
            >
                <Breadcrumbs
                    items={[
                        { label: 'Dashboard', href: AppRoutes.DASHBOARD, current: false },
                        {
                            label: 'Discover Security',
                            href: AppRoutes.DISCOVER,
                            current: true,
                        },
                    ]}
                    currentOnly={false}
                />
            </Box>
            <Container maxW="820px" px={6}>
                <Skeleton isLoaded={isLoaded}>
                    {!!article?.category?.[0] && (
                        <Flex
                            w="fit-content"
                            px={4}
                            pt={3}
                            pb={2}
                            border="1px solid #111111"
                            borderRadius="8px"
                        >
                            <Text>{article?.category?.[0]}</Text>
                        </Flex>
                    )}
                </Skeleton>
                <Skeleton isLoaded={isLoaded} mt={6}>
                    <Heading fontSize="5xl">{article?.title}</Heading>
                </Skeleton>
                <Skeleton isLoaded={isLoaded}>
                    {!!article?.subTitle && (
                        <Text fontWeight="bold" color="#666666" fontSize="xl" mt={1.5}>
                            {article.subTitle}
                        </Text>
                    )}
                </Skeleton>

                <Skeleton isLoaded={isLoaded}>
                    {!!(article?.author || article?.timeToRead) && (
                        <Stack color="#666666" mt={7} spacing={1}>
                            {!!article?.author && <Text>by {article.author}</Text>}
                            <Text>{`${format(
                                new Date(article?.updatedAt),
                                'dd MMM yyyy'
                            )} • ${article?.timeToRead}`}</Text>
                        </Stack>
                    )}
                </Skeleton>

                <Skeleton isLoaded={isLoaded}>
                    {!!sections.length && (
                        <Box
                            mt={9}
                            bg="white"
                            boxShadow="8px 8px 0px 0px #DBDBDB"
                            p={6}
                            border="1px solid #C4C4C4"
                            borderRadius="8px"
                        >
                            <Heading fontSize="2xl">In this article</Heading>
                            <SimpleGrid columns={{ base: 1, md: 2 }} gap={4} mt={4}>
                                {sections.map(({ text, id }) => (
                                    <a href={`#${id}`}>
                                        <Text fontSize="lg" textDecor="underline">
                                            {text}
                                        </Text>
                                    </a>
                                ))}
                            </SimpleGrid>
                        </Box>
                    )}
                </Skeleton>

                <Box my={8}>
                    {renderDocument(article?.articleSections, {
                        renderNode: {
                            [BLOCKS.PARAGRAPH]: (node, children) => (
                                <Text
                                    whiteSpace="pre-wrap"
                                    fontSize="lg"
                                    lineHeight="26px"
                                    my={3}
                                >
                                    {children}
                                </Text>
                            ),
                            [BLOCKS.HEADING_4]: (node, children) => (
                                <>
                                    <Box
                                        as="a"
                                        display="block"
                                        position="relative"
                                        top="-100px"
                                        visibility="hidden"
                                        id={toAnchorTag(
                                            removeNumberedSubstring(
                                                getContentValue(node.content)
                                            )
                                        )}
                                    />
                                    <a
                                        href={`#${toAnchorTag(
                                            removeNumberedSubstring(
                                                getContentValue(node.content)
                                            )
                                        )}`}
                                    >
                                        <Heading as="h4" fontSize="2xl" mt={8}>
                                            {children}
                                        </Heading>
                                    </a>
                                </>
                            ),
                            ...imageAssetNodeOptions({
                                my: 4,
                                borderRadius: 8,
                                objectFit: 'cover',
                            }),
                        },
                    })}
                </Box>
                {!!article?.recommendedArticle && (
                    <Box
                        my={9}
                        bg="white"
                        boxShadow="8px 8px 0px 0px #DBDBDB"
                        p={6}
                        border="1px solid #C4C4C4"
                        borderRadius="8px"
                    >
                        <Heading fontSize="2xl">
                            You've reached the end. Don't leave yet!
                        </Heading>
                        <Text as="span" fontWeight="bold" mr={2}>
                            Up Next:
                        </Text>
                        <Link
                            to={`/discover-security/${article.recommendedArticle.fields.slug}`}
                        >
                            <Text as="span" fontWeight="bold" color="brand.primary.500">
                                {article.recommendedArticle.fields.title}
                            </Text>
                        </Link>
                    </Box>
                )}
            </Container>
        </Box>
    );
};

export default DiscoverArticlePage;

import { BoxProps, ChakraProps } from '@chakra-ui/react';
import { Method } from 'axios';
import React from 'react';
import { Nullable } from '../utils/types';

// UTILITY TYPES
type AnyCase<T extends string> = string extends T
    ? string
    : T extends `${infer F1}${infer F2}${infer R}`
    ? `${Uppercase<F1> | Lowercase<F1>}${Uppercase<F2> | Lowercase<F2>}${AnyCase<R>}`
    : T extends `${infer F}${infer R}`
    ? `${Uppercase<F> | Lowercase<F>}${AnyCase<R>}`
    : '';

export type Callback<T = void, K = void> = (args?: T extends any[] ? [...T] : T) => K;

export type AuthorizationInput = {
    origin?: string;
    httpMethod?: Method;
    path?: string;
    timestamp?: number;
    apiClientKey?: string;
    payload: Nullable<string | object>;
};

// Component Props
export type ChatWidgetProps = {
    open: boolean;
    components?: ChatWidgetComponents;
    icons?: {
        chat: React.ElementType<ChakraProps>;
        close: React.ElementType<ChakraProps>;
        minimize: React.ElementType<ChakraProps>;
    };
    appSyncConfig: AppSyncChatConfig;
    mdn: string;
    onEnd?: Function;
    onStart?: Function;
    onNoAvailableAgent?: Function;
};

export type BaseMessageProps = {
    message: React.ReactNode;
    boxProps?: BoxProps;
};

export type MessageProps = {
    type: AnyCase<MessageTypes>;
    children: React.ReactNode;
};

export type ChatWidgetComponents = {
    message: { [K in AnyCase<MessageTypes>]: React.FC<BaseMessageProps> };
};

// TYPES
export type Message = {
    message: string;
    source: AnyCase<MessageTypes>;
};

export enum MessageTypes {
    SYSTEM = 'SYSTEM',
    USER = 'USER',
    AGENT = 'AGENT',
    CUSTOMER = 'CUSTOMER',
}

// CHAT
export type AppSyncChatConfig = {
    aws_project_region: string;
    aws_appsync_graphqlEndpoint: string;
    aws_appsync_region: string;
    aws_appsync_authenticationType: string;
    aws_cognito_identity_pool_id: string;
    aws_cognito_region: string;
    aws_user_pools_web_client_id: string;
    skillId: string;
    clientName: string;
    clientId: string;
    chatType: string;
};

export type ChatMessage = {
    messageId: string;
    visitorId: string;
    conversationId: string;
    messageType: string;
    interactionType: string;
    sender: string;
    source: string;
    content: string;
    isSent: boolean;
    recipient: string;
    isActive: boolean;
    createdAt: Date;
    sourceMsgId: string;
    translated: boolean;
    sourceLang: string;
    targetLang: string;
    agentResponseTime: number;
    userResponseTime: number;
    violated: boolean;
    messageStatus: string;
    skillId: string;
};

export type ChatRequest = {
    requestId: string;
    visitorId: string;
    taskId: string;
    visitorName: string;
    interactionId: string;
    customerId: string;
    mdn: string;
    skillId: string;
    languageCode: string;
    clientName: string;
    requestStatus: string;
    chatReason: string;
    requestType: string;
    engagementType: string;
    startTimestamp: Date;
    requestChannel: string;
    speedToAnswer: number;
    wrapUpCode: string;
    endTimestamp: Date;
    expertName: string;
    userEmail: string;
    isTransferred: boolean;
    transferredRequestId: string;
    averageResponseTime: number;
    engagementDuration: number;
    violationCount: number;
    chatAcceptTimeStamp: Date;
    chatWaitTime: number;
    expertId: string;
    averageUserResponseTime: number;
    acwStartTimestamp: Date;
    rating?: string;
    endedBy?: string;
    isSurveyRequested: boolean;
};

export type CreateChatRequest = {
    mdn: string;
    visitorId: string;
    userName: string;
    source: string;
    carrier: string;
    programName: string;
    chatReason: string;
    selectedLanguage: `${string}-${string}`;
};

export enum RequestStatus {
    INIT = 'init',
    FETCHING = 'fetching',
    SUCCESS = 'success',
    FAILED = 'failed',
}

export enum FetchStatus {
    INIT = 'init',
    FETCHING = 'fetching',
    COMPLETE = 'complete',
}

export enum ChatStatus {
    Initialized = 'Initialized',
    Connecting = 'Connecting',
    Connected = 'Connected',
    Ended = 'Ended',
}

export type SubscriptionValue<T> = {
    data: T;
};

export type SubscriptionMessage<T = void> = {
    provider: any;
    value: SubscriptionValue<T>;
};

export enum EngagementStatusCode {
    Accepted = 'Accepted',
    Initiated = 'Initiated',
    Canceled = 'Canceled',
    Interacting = 'Interacting',
    AgentEnded = 'Agent Ended',
    CustomerEnded = 'Customer Ended',
    CustomerEnded2 = 'Ended by Customer',
    Abandoned = 'Abandoned',
    Ended = 'Ended', // legacy status code (customer ended)
    Transferred = 'Transferred',
}

import {
    Button,
    Card,
    CardBody,
    CardHeader,
    FormLabel,
    HStack,
    Input,
    Stack,
    Text,
    VStack,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import SmartScanLogo from '../../assets/logos/SmartScanLogo';
import { AppConfig, AppRoutes } from '../../config/config';
import { NullableVar } from '../../config/interface';
import useContent from '../../hooks/useContent';
import { isValidEmail } from '../../utils/helpers';
import RouteLink from '../common/RouteLink';

export type LoginProps = {
    isAuthenticating?: NullableVar<boolean>;
    onRegister?: (email: string) => any;
};

const Register = ({ onRegister, isAuthenticating = false }: LoginProps) => {
    const content = useContent();

    const [email, setEmail] = useState('');
    const [error, setError] = useState('');

    const submitHandler = e => {
        e.preventDefault();
        if (isValidEmail(email) && onRegister) {
            window.dataLayer.push({
                event: 'SecurityAdvisor_DL_Event Registration - Direct Access Email Entry',
                System: 'Security Advisor',
                Partner: AppConfig.carrier,
                Program: AppConfig.program,
                ID: '',
                // Timestamp: now(),
                Module: 'Registration - Direct Access',
                MainSubscriberEmail: email,
            });
            onRegister(email);
        } else {
            setError('Invalid email address');
        }
    };

    return (
        <Card w={{ base: '343px', md: '464px' }} m={'0 auto'}>
            <CardHeader bgGradient="linear(91.62deg, grayscale.400, grayscale.600)">
                <HStack alignItems={'center'} mb={'10px'}>
                    <Text fontSize={24} fontWeight={700} marginRight={'7px'}>
                        {content('forms.registration.header.welcome')}
                    </Text>
                    <SmartScanLogo props={{ style: { margin: 0, minWidth: '172px' } }} />
                </HStack>
                <VStack spacing={2} alignItems={'baseline'}>
                    <Text fontSize="16px">
                        {content('forms.registration.header.content1')}
                    </Text>
                    <Text fontSize="16px">
                        {content('forms.registration.header.content2')}
                    </Text>
                </VStack>
            </CardHeader>
            <CardBody>
                <form onSubmit={submitHandler}>
                    <Stack spacing={1} fontSize={'14px'}>
                        <FormLabel fontWeight={700}>
                            {content('forms.registration.fields.email.label')}
                        </FormLabel>
                        <Input
                            type={'text'}
                            value={email}
                            onChange={e => {
                                setEmail(e.target.value);
                            }}
                            placeholder={content(
                                'forms.registration.fields.email.placeholder'
                            )}
                        />
                        {error && <Text color={'red'}>{error}</Text>}
                        <Text fontSize={'12px'} color={'grayscale.200'}>
                            {content(
                                'forms.registration.fields.email.helper',
                                'Terms of Use and Privacy Policy',
                                (match, i) => (
                                    <span key={i}>
                                        <RouteLink
                                            to={
                                                AppConfig.termsOfUseUrl || AppRoutes.LOGIN
                                            }
                                            textDecor="underline"
                                        >
                                            Terms of Use
                                        </RouteLink>
                                        &nbsp;and&nbsp;
                                        <RouteLink
                                            textDecor="underline"
                                            to={
                                                AppConfig.privacyPolicyUrl ||
                                                AppRoutes.LOGIN
                                            }
                                        >
                                            Privacy Policy
                                        </RouteLink>
                                    </span>
                                )
                            )}
                        </Text>

                        <Button type="submit" isLoading={isAuthenticating || false}>
                            {content('forms.registration.fields.submit.label')}
                        </Button>
                    </Stack>
                </form>
            </CardBody>
        </Card>
    );
};

export default Register;

import {
    HStack,
    VStack,
    Card,
    CardHeader,
    CardBody,
    Box,
    Divider,
    Input,
    InputGroup,
    InputRightAddon,
    Image,
    Heading,
    Spacer,
    CloseButton,
    CardFooter,
    Flex,
    useBoolean,
    Spinner,
} from '@chakra-ui/react';
import linkifyHtml from 'linkify-html';
import * as React from 'react';
import AttachFileIconSvg from './assets/AttachFileIconSvg';
import CloseIconSvg from './assets/CloseIconSvg';
import MinimizeIconSvg from './assets/MinimizeIconSvg';
import SendIconSvg from './assets/SendIconSvg';
import ExpertImage from './assets/images/experts.png';
import { Field, Formik } from 'formik';
import { getComponentStyles } from '../config/theme';
import HeaderMobileLogoIcon from '../assets/HeaderMobileLogoIcon';
import SecurityAdvisorLogoOnlyIcon from '../assets/SecurityAdvisorLogoOnlyIcon';
import ChatIconSvg from './assets/ChatIconSvg';
import { account } from '../entities/session';
import { ChatMessage, ChatWidgetProps, FetchStatus, MessageTypes } from './types';
import MessageComponent from './components/Message';
import { ChatManager } from './ChatManager';
import { configure } from './services/ChatService';
import { noop } from '../utils/helpers';
import {
    chatConversationIdEntity,
    chatRequestIdEntity,
    chatVisitorIdEntity,
    getChatSession,
    hasActiveChatEntity,
} from './entities/ChatEntities';
import { Nullable } from '../utils/types';

const { useState, useEffect, useCallback, useMemo } = React;

export const ChatWidget: React.FC<ChatWidgetProps> = ({
    icons = {
        chat: ChatIconSvg,
        close: CloseIconSvg,
        minimize: MinimizeIconSvg,
    },
    open,
    mdn,
    appSyncConfig,
    onEnd = noop,
    onNoAvailableAgent = noop,
}) => {
    const ChatIcon = icons.chat;
    const CloseIcon = icons.close;
    const MinimizeIcon = icons.minimize;

    const { divider } = getComponentStyles('Header');

    const chatRequestId = chatRequestIdEntity.use();
    const chatConversationId = chatConversationIdEntity.use();
    const chatVisitorId = chatVisitorIdEntity.use();
    const hasActiveChat = hasActiveChatEntity.use();
    const accountInfo = account.use();

    const [isOpen, setIsOpen] = useBoolean(open);
    const [requestId, setRequestId] = useState<Nullable<string>>(null);
    const [conversationId, setConversationId] = useState<Nullable<string>>(null);
    const [visitorId, setVisitorId] = useState<Nullable<string>>(null);
    const [activeChat, setActiveChat] = useBoolean();

    const [messages, setMessages] = useState<Array<ChatMessage>>([]);
    const [isChatConnected, setIsChatConected] = useBoolean();
    const [isNoAvailableAgents, setIsNoAvailableAgents] = useBoolean();
    const [fetchChatRequestIdStatus, setFetchChatRequestIdStatus] = useState<FetchStatus>(
        FetchStatus.INIT
    );

    const submitHandler = ({ message }, { resetForm }) => {
        chatManager.sendMessage(message);
        resetForm();
    };

    configure(appSyncConfig);

    let chatManager = useMemo(
        () =>
            new ChatManager(
                appSyncConfig.clientName,
                'DEV',
                {
                    engagementApiUrl: new URL(
                        `${process.env.REACT_APP_ENGAGEMENT_API_WRAPPER_URL}${process.env.REACT_APP_ENGAGEMENT_API_WRAPPER_PATH}`
                    ),
                    path: process.env.REACT_APP_ENGAGEMENT_API_WRAPPER_PATH ?? '',
                    program: process.env.REACT_APP_ENGAGEMENT_API_WRAPPER_PROGRAM ?? '',
                    channel: process.env!.REACT_APP_ENGAGEMENT_API_WRAPPER_CHANNEL ?? '',
                    engagementApiKey:
                        process.env.REACT_APP_ENGAGEMENT_API_ENGAGEMENT_API_KEY ?? '',
                },
                accountInfo.primaryEmail.email,
                {
                    chatType: appSyncConfig.chatType,
                    skillId: appSyncConfig.skillId,
                    clientId: appSyncConfig.clientId,
                },
                mdn
            ),
        [appSyncConfig, accountInfo?.primaryEmail?.email]
    );

    const formatMessages = (messages: Array<ChatMessage>) => {
        return messages
            .map((message: ChatMessage) => {
                let newMessage: ChatMessage = message;
                switch (message.messageType.toLowerCase()) {
                    case 'plain text':
                    case 'text':
                        newMessage.content = linkifyHtml(newMessage.content, {
                            target: { url: '_blank' },
                        });
                        break;
                }
                return newMessage;
            })
            .sort((a, b) => {
                if (a.createdAt < b.createdAt) {
                    return -1;
                }

                if (a.createdAt > b.createdAt) {
                    return 1;
                }
                return 0;
            });
    };

    const chatConnected = useCallback(() => {
        setIsChatConected.on();
        setIsNoAvailableAgents.off();
    }, [setIsChatConected, setIsNoAvailableAgents]);

    const terminateChatSession = useCallback(() => {
        if (fetchChatRequestIdStatus === FetchStatus.COMPLETE) {
            chatManager.end();
            setIsChatConected.off();
            setIsNoAvailableAgents.off();
            setMessages([]);
            setActiveChat.off();
            setFetchChatRequestIdStatus(FetchStatus.INIT);
            console.log('Chat is now terminated.');
            onEnd();
        }
    }, [
        fetchChatRequestIdStatus,
        chatManager,
        onEnd,
        setActiveChat,
        setIsChatConected,
        setIsNoAvailableAgents,
    ]);

    const chatDisconnected = useCallback(() => {
        terminateChatSession();
    }, [terminateChatSession]);

    const messageReceived = useCallback(async () => {
        const incomingMessages = await chatManager.getAllMessages();
        console.log('INCOMING MESSAGES', incomingMessages);
        setMessages(formatMessages(incomingMessages));
    }, [chatManager]);

    const startChat = useCallback(async () => {
        chatManager.onConnected = chatConnected;
        chatManager.onMessageReceived = messageReceived;
        chatManager.onDisconnected = chatDisconnected;

        await chatManager.create(activeChat, requestId, conversationId, visitorId);
        const availableAgents = await chatManager.hasAvailableAgents();
        const messages = await chatManager.getAllMessages();
        setMessages(formatMessages(messages));

        if (!activeChat) {
            setIsNoAvailableAgents[!availableAgents ? 'on' : 'off']();
        } else {
            setIsNoAvailableAgents.off();
        }
    }, [
        activeChat,
        chatConnected,
        chatDisconnected,
        chatManager,
        conversationId,
        messageReceived,
        requestId,
        setIsNoAvailableAgents,
        visitorId,
    ]);

    useEffect(() => {
        if (isOpen) {
            startChat();
        }
    }, [isOpen, startChat]);

    useEffect(() => {
        if (isNoAvailableAgents) {
            console.log('No agents are available as of the moment.');
            onNoAvailableAgent();
        }
    }, [isNoAvailableAgents, onNoAvailableAgent]);

    useEffect(() => {
        if (fetchChatRequestIdStatus === FetchStatus.INIT) {
            setRequestId(null);
            setConversationId(null);
            setVisitorId(null);
            setActiveChat.off();
            setFetchChatRequestIdStatus(FetchStatus.FETCHING);
            getChatSession(chatRequestId);
        }

        if (hasActiveChat !== null) {
            setFetchChatRequestIdStatus(FetchStatus.COMPLETE);

            setRequestId(chatRequestId);
            setConversationId(chatConversationId);
            setVisitorId(chatVisitorId);
            setActiveChat[hasActiveChat ? 'on' : 'off']();

            if (chatRequestId && conversationId && visitorId) {
                chatManager.requestId = chatRequestId;
                chatManager.conversationId = conversationId;
                chatManager.visitorId = visitorId;
                chatManager.activeChat = hasActiveChat;

                chatRequestIdEntity.set(chatRequestId);
                chatConversationIdEntity.set(chatConversationId);
                chatVisitorIdEntity.set(chatVisitorId);
            }
        }
    }, [
        hasActiveChat,
        activeChat,
        requestId,
        conversationId,
        fetchChatRequestIdStatus,
        chatRequestId,
        chatConversationId,
        chatVisitorId,
        chatManager,
        setActiveChat,
        visitorId,
    ]);

    // const triggerEndChat = useCallback(terminateChatSession, [terminateChatSession]);

    if (!isOpen) {
        return (
            <ChatIcon
                w={{ base: '48px', md: '60px' }}
                h={{ base: '48px', md: '60px' }}
                position={'fixed'}
                right={{ base: 4, md: 6 }}
                bottom={{ base: 4, md: 6 }}
                _hover={{ cursor: 'pointer' }}
                onClick={setIsOpen.on}
            />
        );
    }
    return (
        <VStack
            position={'fixed'}
            right={{ md: 6, base: 0 }}
            bottom={{ md: 6, base: 0 }}
            rounded={{ md: 4, base: 0 }}
            gap={2}
            h={{ md: 'calc(100vh - 100px)', base: '100vh' }}
            zIndex={9999}
            justifyContent={'flex-end'}
            w={{ md: 'auto', base: 'full' }}
        >
            <Flex
                justifyContent={'flex-end'}
                w={{ md: 'auto', base: 'full' }}
                flexDirection={'column'}
            >
                <Box display={{ md: 'none', base: 'flex' }} background={'black'} p={4}>
                    <HStack w={'full'}>
                        <Box display={{ base: 'flex', md: 'none' }} width={'200px'}>
                            <HeaderMobileLogoIcon mr={'10px'} mt={'4px'} />
                            <Divider {...divider} />
                            <SecurityAdvisorLogoOnlyIcon ml={'10px'} />
                        </Box>

                        <Spacer />
                        <CloseButton onClick={() => setIsOpen.off} color={'white'} />
                    </HStack>
                </Box>
                <Card
                    w={{ md: '344px', base: 'full' }}
                    maxH={{ md: 'calc(100vh - 100px)', base: 'calc(100vh - 64px)' }}
                    variant={{ md: 'outline', base: 'unstyled' }}
                    alignSelf={'flex-end'}
                    h={{ md: '644px', base: '100%' }}
                    borderRadius={{ md: '8px', base: 0 }}
                >
                    <CardHeader px={'17px'} pt={'17px'} pb={0} borderRadius={0}>
                        <HStack gap={2}>
                            <Image src={ExpertImage} />
                            <VStack alignItems={'flex-start'} justifyContent={'center'}>
                                <Heading fontSize={16} fontWeight={700} color="#3D3D3B">
                                    Your team of experts
                                </Heading>
                                <Heading
                                    fontSize={12}
                                    fontWeight={400}
                                    color="#3D3D3B"
                                    mt={'0 !important'}
                                >
                                    Ask us anything
                                </Heading>
                            </VStack>
                        </HStack>
                    </CardHeader>
                    <CardBody
                        display={'flex'}
                        justifyContent={'space-between'}
                        flexDirection={'column-reverse'}
                        gap={1}
                        py={'17px'}
                        pb={'4px'}
                        maxH={{ md: 'calc(100vh - 150px)', base: 'none' }}
                        height={{ md: 'calc(100vh - 150px)', base: '100%' }}
                        overflow={'auto'}
                    >
                        {!isChatConnected && !isNoAvailableAgents && (
                            <Flex
                                height={'full'}
                                alignItems={'center'}
                                justifyContent={'center'}
                            >
                                <Spinner size={'xl'} justifyContent={'center'} />
                            </Flex>
                        )}

                        {!isNoAvailableAgents && (
                            <VStack width={'full'}>
                                {messages.map(({ content, source }, i) => (
                                    <MessageComponent
                                        key={`message-${i}`}
                                        type={source as MessageTypes}
                                    >
                                        {content}
                                    </MessageComponent>
                                ))}
                            </VStack>
                        )}

                        {isNoAvailableAgents && <Flex>No available agents</Flex>}
                    </CardBody>
                    <Divider
                        color={'#E6E6EB'}
                        mx={'auto'}
                        mt={{ md: '19px', base: '8px' }}
                        mb={0}
                        width={{ md: 'calc(100% - 34px)', base: 'calc(100% - 16px)' }}
                    />
                    <CardFooter
                        px={{ md: '17px', base: '14px' }}
                        pt={{ md: '8px', base: '4px' }}
                        pb={'15px'}
                    >
                        <Formik
                            initialValues={{
                                message: '',
                            }}
                            onSubmit={submitHandler}
                        >
                            {({ handleSubmit }) => (
                                <form onSubmit={handleSubmit}>
                                    <Field name="message">
                                        {({ field, form }) => (
                                            <InputGroup w={'full'}>
                                                <Input
                                                    {...field}
                                                    px={'7px'}
                                                    py={'11px'}
                                                    border="none"
                                                    placeholder="Type a message"
                                                    _focusVisible={{ border: 'none' }}
                                                    disabled={!isChatConnected}
                                                />
                                                <InputRightAddon
                                                    bg={'none'}
                                                    border={'none'}
                                                    px={0}
                                                    children={
                                                        <HStack>
                                                            <SendIconSvg
                                                                w={'24px'}
                                                                h={'24px'}
                                                                _hover={{
                                                                    cursor: isChatConnected
                                                                        ? 'pointer'
                                                                        : 'not-allowed',
                                                                }}
                                                                onClick={() =>
                                                                    isChatConnected &&
                                                                    form.submitForm()
                                                                }
                                                            />
                                                            <AttachFileIconSvg
                                                                w={'24px'}
                                                                h={'24px'}
                                                                _hover={{
                                                                    cursor: isChatConnected
                                                                        ? 'pointer'
                                                                        : 'not-allowed',
                                                                }}
                                                            />
                                                        </HStack>
                                                    }
                                                />
                                            </InputGroup>
                                        )}
                                    </Field>
                                </form>
                            )}
                        </Formik>
                    </CardFooter>
                </Card>
            </Flex>
            <HStack alignSelf={'flex-end'} display={{ md: 'flex', base: 'none' }}>
                <MinimizeIcon
                    w={{ md: '48px' }}
                    h={{ md: '48px' }}
                    _hover={{ cursor: 'pointer ' }}
                    onClick={setIsOpen.off}
                />
                <CloseIcon
                    w={{ md: '48px' }}
                    h={{ md: '48px' }}
                    _hover={{ cursor: 'pointer ' }}
                    onClick={setIsOpen.off}
                />
            </HStack>
        </VStack>
    );
};

export default ChatWidget;

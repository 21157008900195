export const Api = {
    LOGIN: `${process.env.REACT_APP_API_BASE_URL}/login/email`,
    VERIFY_OTP: `${process.env.REACT_APP_API_BASE_URL}/login/otp`,
    REGISTER: () => `${process.env.REACT_APP_API_BASE_URL}/register/primary/verify/email`,
    VERIFY_MOBILE_NUMBER: () =>
        `${process.env.REACT_APP_API_BASE_URL}/register/primary/verify/sms`,
    VERIFY_SMS_OTP: () =>
        `${process.env.REACT_APP_API_BASE_URL}/register/primary/verify/sms/otp`,
    VERIFY_REGISTRATION_EMAIL_OTP: () =>
        `${process.env.REACT_APP_API_BASE_URL}/register/primary/verify/email/otp`,
    GET_ACCOUNT_DETAILS: ({ email, program, carrier }) =>
        `${process.env.REACT_APP_API_BASE_URL}/user/details?program=${program}&carrier=${carrier}&email=${email}`,
    GET_ASSETS: ({ email, rescan }) => {
        if (rescan) {
            return `${process.env.REACT_APP_API_BASE_URL}/assets/${email}?rescan=true`;
        } else {
            return `${process.env.REACT_APP_API_BASE_URL}/assets/${email}`;
        }
    },
    GET_ASSESSMENTS_SUMMARY: ({ email }) =>
        `${process.env.REACT_APP_API_BASE_URL}/assessment/summary/${email}`,
    GET_ASSESSMENTS_RESULT: ({ email }) =>
        `${process.env.REACT_APP_API_BASE_URL}/assessment/result/${email}`,
    GET_ASSESSMENTS_GUIDE: (title: string) =>
        `${process.env.REACT_APP_API_BASE_URL}/assessment/guide?title=${title}`,
    RESOLVE_ASSESSMENT: (email: string) =>
        `${process.env.REACT_APP_API_BASE_URL}/assessment/guide/${email}/resolve`,
    GET_ASSESSMENT_QUESTIONS: `${process.env.REACT_APP_API_BASE_URL}/assessment/questions`,
    GET_ASSET_BY_TYPE: ({ email, asset }) =>
        `${process.env.REACT_APP_API_BASE_URL}/assets/${email?.toLowerCase()}/${asset
            ?.replaceAll(' ', '-')
            .toLowerCase()}`,
    GET_ASSET_BY_TYPE_GROUPBY: ({ email, asset, groupby = 'value' }) =>
        `${process.env.REACT_APP_API_BASE_URL}/assets/${email?.toLowerCase()}/${asset
            ?.replaceAll(' ', '-')
            .toLowerCase()}?groupBy=${groupby}`,
    UNMASKED_VALUE: ({ email, asset, documentId }) =>
        `${
            process.env.REACT_APP_API_BASE_URL
        }/assets/${email?.toLowerCase()}/${asset?.toLowerCase()}/unmask?documentId=${documentId}`,
    MARKED_RESOLVE: ({ email, asset, documentId }) =>
        `${
            process.env.REACT_APP_API_BASE_URL
        }/assets/${email?.toLowerCase()}/${asset.toLowerCase()}/resolve?documentId=${documentId}`,
    GET_BREACH_RESOLUTION_STEPS: (asset: string) =>
        `${process.env.REACT_APP_API_BASE_URL}/breach/resolutionSteps/${asset}`,
    GET_DASHBOARD_ARTICLES: () =>
        `${process.env.REACT_APP_API_BASE_URL}/articles/dashboard`,
    GET_ALL_SECURITY_ARTICLES: ({ topic }) =>
        `${process.env.REACT_APP_API_BASE_URL}/articles/security?topic=${topic}`,
    GET_SECURITY_ARTICLE: ({ slug }) =>
        `${process.env.REACT_APP_API_BASE_URL}/articles/security/content/${slug}`,
    GET_ALL_LATEST_ARTICLES: () =>
        `${process.env.REACT_APP_API_BASE_URL}/articles/latest`,
    GET_ARTICLE_FILTERS: () =>
        `${process.env.REACT_APP_API_BASE_URL}/articles/security/topics`,
    GET_PROFILE: ({ email }) =>
        `${process.env.REACT_APP_API_BASE_URL}/user/profile?email=${email}`,
    UPDATE_PROFILE: ({ email }) =>
        `${process.env.REACT_APP_API_BASE_URL}/user/profile?email=${email}`,
    DELETE_SECONDARY_EMAIL: ({ email }) =>
        `${process.env.REACT_APP_API_BASE_URL}/user/remove?secondaryEmail=${email}`,
    REGISTER_SECONDARY_EMAIL: () =>
        `${process.env.REACT_APP_API_BASE_URL}/register/secondary`,
    VERIFY_SECONDARY_EMAIL: () =>
        `${process.env.REACT_APP_API_BASE_URL}/register/secondary/verify`,
    LOGOUT: () => `${process.env.REACT_APP_API_BASE_URL}/logout`,
    GET_ONBOARDING_STEPS: () => `${process.env.REACT_APP_API_BASE_URL}/onboarding`,
    GET_WEB_CONTENTS: ({ carrier }) =>
        `${process.env.REACT_APP_API_BASE_URL}/content?carrier=${carrier}`,
};
